import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { Site, ListResponse } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import {
    SiteDetailsPayload,
    SiteListPayload,
    details as detailsApiCall,
    list as listApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
    upload as uploadApiCall,
    SiteUploadPayload,
} from '../api/sites';

// State

export interface SitesState {
    list: RequestState<ListResponse<Site>>;
    search: RequestState<ListResponse<Site>>;
    details: RequestState<Site | undefined>;
    create: RequestState<Site | undefined>;
    update: RequestState<Site | undefined>;
    remove: RequestState<Site | undefined>;
    upload: RequestState<Site | undefined>;
}

const initialState: SitesState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    search: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    create: {
        data: undefined,
        loading: false,
        success: false,
    },
    update: {
        data: undefined,
        loading: false,
        success: false,
    },
    remove: {
        data: undefined,
        loading: false,
        success: false,
    },
    upload: {
        data: undefined,
        loading: false,
        success: false,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    SitesState['list'],
    SiteListPayload,
    ListResponse<Site>
>('sites/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const search = new EzeeAsyncAction<
    SitesState['search'],
    SiteListPayload,
    ListResponse<Site>
>('sites/search', initialState.search, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.search,
    }),
});

export const details = new EzeeAsyncAction<
    SitesState['details'],
    SiteDetailsPayload,
    Site
>('sites/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const create = new EzeeAsyncAction<
    SitesState['create'],
    Site,
    Site
>('sites/create', initialState.create, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

export const update = new EzeeAsyncAction<
    SitesState['update'],
    Site,
    Site
>('sites/update', initialState.update, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const remove = new EzeeAsyncAction<
    SitesState['remove'],
    Site,
    Site
>('sites/remove', initialState.remove, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

export const upload = new EzeeAsyncAction<
    SitesState['upload'],
    SiteUploadPayload,
    Site
>('operations/upload', initialState.upload, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.upload,
    }),
});

// Reducer

export const sitesReducer = combineReducers<SitesState>({
    details: details.reducer,
    list: list.reducer,
    search: search.reducer,
    create: create.reducer,
    update: update.reducer,
    remove: remove.reducer,
    upload: upload.reducer,
});

// Saga

export function* sitesSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(search.type.trigger, simpleAsyncSaga(listApiCall, search));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(upload.type.trigger, simpleAsyncSaga(uploadApiCall, upload));
}

// Store helpers

export const getSitesState = (state: MainReducerState) => state.sites;
