import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Select, Spin, notification, Row, Col, Divider, Typography, Radio, InputNumber } from 'antd';
import { MainReducerState } from '../../store/reducers';
import { connect } from 'react-redux';
import { BadgeType, ServiceType, Site, PSPName } from '../../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import genericMessages from '../../locale/genericMessages';
import { FormattedMessage, useIntl } from 'react-intl';
import formMessages from '../../locale/formMessages';
import {
    SitesState,
    details as siteDetails,
    update as siteUpdate,
    create as siteCreate,
    upload as siteUpload,
} from '../../store/actions/sites';
import ColorPicker from '../../components/ColorPicker';
import { TranslateBadgeType, TranslateServiceType, TranslatePspType } from '../../helpers/translate';
import UploadFile from '../../components/UploadFile';
import { isString } from '../../helpers';
import PageDrawer from '../../components/PageDrawer';

interface SiteDrawerProps extends RouteProps {
    id?: Site['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    sites: SitesState;
    getDetails: typeof siteDetails.trigger;
    detailsReset: typeof siteDetails.reset;
    update: typeof siteUpdate.trigger;
    updateReset: typeof siteUpdate.reset;
    create: typeof siteCreate.trigger;
    createReset: typeof siteCreate.reset;
    upload: typeof siteUpload.trigger;
    uploadReset: typeof siteUpload.reset;
}

const SiteDrawer: FC<SiteDrawerProps> = ({
    id,
    isVisible,
    onClose,
    onSuccess,

    sites,
    getDetails,
    detailsReset,
    update,
    updateReset,
    create,
    createReset,
    upload,
    uploadReset,
}) => {

    const [formValues, setFormValues] = useState<any>();
    const { formatMessage } = useIntl();
    const site: Site | undefined = sites.details.data;
    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible && id) {
            getDetails({ id });
        } else {
            detailsReset();
            // setLogo(undefined);
            // setShowLogoUpload(true);
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (sites.details.data) {
            form.setFieldsValue(sites.details.data);
            setFormValues(sites.details.data);
            // setShowLogoUpload(!sites.details.data.logoImage);
        }
    }, [sites.details.data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (sites.update.success || sites.create.success) {
            form.resetFields();
            updateReset();
            createReset();
            if (onSuccess) {
                onSuccess();
            }

            if (formValues?.logoImage && !isString(formValues?.logoImage)) {
                upload({ id, file: formValues?.logoImage, type: 'logo' });
            }

            if (formValues?.loginImage && !isString(formValues?.loginImage)) {
                upload({ id, file: formValues?.loginImage, type: 'loginimage' });
            }

            if (formValues?.bannerImage && !isString(formValues?.bannerImage)) {
                upload({ id, file: formValues?.bannerImage, type: 'banner' });
            }

            /*if (logo) {
                upload({id, file: logo, type: 'logo'});
            }*/
        }

        if (sites.update.error || sites.create.error) {
            updateReset();
            createReset();
            notification.error({
                message: formatMessage({
                    id: 'error.save_site', // obligatoire et unique
                    defaultMessage: 'Une erreur est survenue lors de la sauvegarde de ce site',
                }),
                placement: 'bottomRight',
            });
        }
    }, [sites.update.success, sites.create.success, sites.update.error, sites.create.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const onValuesChange: FormProps['onValuesChange'] = (changes, values) => {
        setFormValues(values);
    };

    const onFormFinish: FormProps['onFinish'] = (values) => {

        if (values.logoImage) { delete values.logoImage; }
        if (values.loginImage) { delete values.loginImage; }
        if (values.bannerImage) { delete values.bannerImage; }

        form.validateFields().then(() => {
            if (id) {
                values.id = id;
                update(values);
            } else {
                create(values);
            }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        form.resetFields();
        onClose();
    };

    const getPageBySlug = (slug: string) => {
        return site?.pages.find((page) => page.slug === slug);
    };

    // ---------------------------------------
    // Render the page

    return (
        <Drawer
            className="site-drawer"
            title={(!id) ? formatMessage(genericMessages.siteAdd) : formatMessage(genericMessages.siteEdit)}
            width={900}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={sites.details.loading}>
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                    hideRequiredMark
                >
                    <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                label={formatMessage(genericMessages.siteCommercialName)}
                                name="name"
                                rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                            >
                                <Input size="large" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                label={formatMessage(genericMessages.apiToken)}
                                name="apimKey"
                                rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                            >
                                <Input size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                label={formatMessage(genericMessages.phone)}
                                name="phone"
                                rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                            >
                                <Input type="tel" size="large" autoComplete="none" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                label={formatMessage(genericMessages.siteId)}
                                name="siteId"
                                rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                            >
                                <Input size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                label={formatMessage(genericMessages.siteCode)}
                                name="siteCode"
                                rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                            >
                                <InputNumber type="number" size="large" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label={formatMessage(genericMessages.siteServices)}
                        name="services"
                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                    >
                        <Select
                            size="large"
                            mode="multiple"
                        >
                            <Select.Option value={ServiceType.payExt}>{TranslateServiceType(ServiceType.payExt)}</Select.Option>
                            <Select.Option value={ServiceType.ppo}>{TranslateServiceType(ServiceType.ppo)}</Select.Option>
                        </Select>
                    </Form.Item>

                    {formValues?.services && formValues?.services.length > 0 && (
                        <>
                            <Divider />

                            <div className="mb-3">
                                <Typography.Title level={2} className="text-primary">
                                    Services
                                    <FormattedMessage {...genericMessages.siteServices} />
                                </Typography.Title>
                            </div>
                        </>
                    )}

                    {formValues?.services && formValues.services.map((service: ServiceType, index: number) => (
                        <div key={'service-' + service}>
                            <strong>{TranslateServiceType(service)}</strong>
                            <Form.Item name={['authentications', index, 'type']} noStyle initialValue={service}>
                                <Input type="hidden" />
                            </Form.Item>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label={formatMessage(genericMessages.siteLogin)}
                                        name={['authentications', index, 'login']}
                                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label={formatMessage(formMessages.password_label)}
                                        name={['authentications', index, 'password']}
                                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                    >
                                        <Input.Password size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    ))}
                    {formValues?.services && formValues?.services.length > 0 && (
                        <Divider />
                    )}
                    <Form.Item
                        label={formatMessage(genericMessages.pspType)}
                        name={['psp', 'name']}
                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                    >
                        <Select
                            size="large"
                        >
                            <Select.Option value={PSPName.payline}>{TranslatePspType(PSPName.payline)}</Select.Option>
                            <Select.Option value={PSPName.nmi}>{TranslatePspType(PSPName.nmi)}</Select.Option>
                            <Select.Option value={PSPName.advam}>{TranslatePspType(PSPName.advam)}</Select.Option>
                            <Select.Option value={PSPName.globalPayment}>{TranslatePspType(PSPName.globalPayment)}</Select.Option>
                        </Select>
                    </Form.Item>
                    {formValues?.psp && (
                        <>
                            <Divider />

                            <div className="mb-3">
                                <Typography.Title level={2} className="text-primary">
                                    <FormattedMessage id="psp.data" defaultMessage="PSP Données" />
                                </Typography.Title>
                            </div>
                        </>
                    )}
                    {formValues?.psp && (
                        (
                            (formValues?.psp?.name === PSPName.payline) && (
                                <div>
                                    <Form.Item
                                        label={formatMessage(genericMessages.pspContractNumber)}
                                        name={['psp', 'payline_contractNumber']}
                                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                </div>
                            )
                        ) || (
                            (formValues?.psp?.name === PSPName.nmi) && (
                                <div>
                                    <Form.Item
                                        label={formatMessage(genericMessages.secretKey)}
                                        name={['psp', 'nmi_security_key']}
                                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                </div>
                            )
                        ) || (
                            (formValues?.psp?.name === PSPName.advam) && (
                                <div>
                                    <Form.Item
                                        label={formatMessage(genericMessages.pspContractNumber)}
                                        name={['psp', 'advam_account_number']}
                                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item
                                        label={formatMessage(formMessages.username_label)}
                                        name={['psp', 'operator_username']}
                                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item
                                        label={formatMessage(formMessages.password_label)}
                                        name={['psp', 'operator_password']}
                                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                </div>
                            )
                        ) || (
                            (formValues?.psp?.name === PSPName.globalPayment) && (
                                <div>
                                    <Form.Item
                                        label={formatMessage(formMessages.globalPayment_merchant_id)}
                                        name={['psp', 'global_payment_merchant_id']}
                                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item
                                        label={formatMessage(formMessages.username_label)}
                                        name={['psp', 'global_payment_account']}
                                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item
                                        label={formatMessage(formMessages.password_label)}
                                        name={['psp', 'global_payment_rebate_password']}
                                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item
                                        label={formatMessage(formMessages.globalPayment_secret)}
                                        name={['psp', 'global_payment_shared_secret']}
                                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                </div>
                            )
                        ))
                    }

                    <Divider />

                    <div className="mb-3">
                        <Typography.Title level={2} className="text-primary">
                            <FormattedMessage id="site.theme" defaultMessage="Thème" />
                        </Typography.Title>
                    </div>

                    <Form.Item
                        label={formatMessage(genericMessages.logo)}
                        name="logoImage"
                    >
                        <UploadFile />
                    </Form.Item>

                    <Form.Item
                        label={formatMessage(genericMessages.themeColor)}
                        name="themeColor"
                    >
                        <ColorPicker initialValue={site?.themeColor} />
                    </Form.Item>

                    {(formValues?.services && formValues.services.includes(ServiceType.ppo)) && (
                        <>
                            <Divider />

                            <div className="mb-3">
                                <Typography.Title level={2} className="text-primary">
                                   <FormattedMessage {...genericMessages.ppoTitle} />
                                </Typography.Title>
                            </div>

                            <div className="mb-3">
                                <strong>Paramètres des abonnements</strong>
                            </div>

                            <Form.Item
                                label="Nombre maximum de plaques d’immatriculation "
                                name="nbPlates"
                                rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) },
                                {
                                    validator: (_, value) => {
                                        if (value && (Number(value) > 5 || Number(value) < 0)) {
                                            return Promise.reject('Nombre de plaque doit être entre 0 et 5');
                                        }
                                        return Promise.resolve();
                                    },
                                }]}
                            >
                                <InputNumber type="number" size="large" style={{ width: '100%' }} />
                            </Form.Item>

                            <Row gutter={16}>
                                <Col className="gutter-row" span={14}>
                                    <Form.Item
                                        label={formatMessage(genericMessages.badgeType)}
                                        name="badgeType"
                                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                    >
                                        <Radio.Group buttonStyle="solid" size="large" className="separate-mobile">
                                            <Radio.Button value={BadgeType.dematerialized}>
                                                {TranslateBadgeType(BadgeType.dematerialized)}
                                            </Radio.Button>
                                            <Radio.Button value={BadgeType.physical}>
                                                {TranslateBadgeType(BadgeType.physical)}
                                            </Radio.Button>
                                            <Radio.Button value={BadgeType.dematerializedAndPhysical}>
                                                {TranslateBadgeType(BadgeType.dematerializedAndPhysical)}
                                            </Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={10}>
                                    <Form.Item
                                        label={formatMessage(genericMessages.siteExpirationWarning)}
                                        name="deadline"
                                        rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                    >
                                        <Radio.Group buttonStyle="solid" size="large">
                                            <Radio.Button value={true}>
                                                Oui
                                            </Radio.Button>
                                            <Radio.Button value={false}>
                                                Non
                                            </Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div className="mb-2">
                                <strong>Paramètres du site internet</strong>
                            </div>

                            <Form.Item
                                label={formatMessage(genericMessages.sitePhotoHeader)}
                                name="bannerImage"
                            >
                                <UploadFile />
                            </Form.Item>

                            <Form.Item
                                label={formatMessage(genericMessages.siteLoginPhoto)}
                                name="loginImage"
                            >
                                <UploadFile />
                            </Form.Item>

                            {isVisible && (
                                <Form.Item
                                    label={formatMessage(genericMessages.siteFooterContent)}
                                    className="pages-list"
                                >
                                    <Form.Item
                                        name="privacyPolicy"
                                    >
                                        <PageDrawer initialValue={getPageBySlug('privacyPolicy')} label={formatMessage(genericMessages.sitePrivacyPolicy)} />
                                    </Form.Item>

                                    <Form.Item
                                        name="cgv"
                                    >
                                        <PageDrawer initialValue={getPageBySlug('cgv')} label={formatMessage(genericMessages.siteSalesConditions)} />
                                    </Form.Item>

                                    <Form.Item
                                        name="cgu"
                                    >
                                        <PageDrawer initialValue={getPageBySlug('cgu')} label={formatMessage(genericMessages.siteUsageRule)} />
                                    </Form.Item>

                                    <Form.Item
                                        name="legalNotice"
                                    >
                                        <PageDrawer initialValue={getPageBySlug('legalNotice')} label={formatMessage(genericMessages.siteLegalNotice)} />
                                    </Form.Item>
                                </Form.Item>
                            )}

                        </>
                    )}

                    <Form.Item className="cta-submit">
                        <Button
                            type="primary"
                            size="large"
                            shape="round"
                            block
                            htmlType="submit"
                            loading={sites.create.loading || sites.update.loading}
                        >
                            {site ? formatMessage(genericMessages.saveChanges) : formatMessage({id: 'site.create', defaultMessage: 'Créer le site'})}
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    sites: state.sites,
});

export default connect(
    mapStateToProps,
    {
        getDetails: siteDetails.trigger,
        detailsReset: siteDetails.reset,
        create: siteCreate.trigger,
        createReset: siteCreate.reset,
        update: siteUpdate.trigger,
        updateReset: siteUpdate.reset,
        upload: siteUpload.trigger,
        uploadReset: siteUpload.reset,
    },
)(SiteDrawer);
