import React from 'react';
import { IntlProvider as ReactIntlProvider, IntlConfig } from 'react-intl';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillListFormat } from '@formatjs/intl-listformat/should-polyfill';
import { Spin, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import { Locale } from 'antd/es/locale-provider';
import moment from 'moment';

import apiClient from '../store/api/_client';
import { Language } from '../types';
import { defaultLanguage, LocaleContext } from '../context/LocaleContext';

interface LangImport {
    [lang: string]: any;
}

const availableLangs = Object.values(Language);
const reactIntlPluralLocaleData = availableLangs.reduce<LangImport>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => import(`@formatjs/intl-pluralrules/locale-data/${lang}`),
    }),
    {},
);
const reactIntlRelativeLocaleData = availableLangs.reduce<LangImport>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => import(`@formatjs/intl-relativetimeformat/locale-data/${lang}`),
    }),
    {},
);
const reactIntlListFormatLocaleData = availableLangs.reduce<LangImport>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => import(`@formatjs/intl-listformat/locale-data/${lang}`),
    }),
    {},
);
const momentLocaleData = availableLangs.filter((lang) => lang !== Language.en)
.reduce<LangImport>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => import(`moment/locale/${lang}.js`),
    }),
    {},
);
const dayJsLocaleData = availableLangs.reduce<LangImport>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => import(`dayjs/locale/${lang}.js`),
    }),
    {},
);
const l10nFiles = availableLangs.reduce<LangImport>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => import(`../locale/l10n/${lang}.json`),
    }),
    {},
);
const antdLocales: LangImport = {
    fr: async () => import('antd/es/locale/fr_FR.js'),
    en: async () => import('antd/es/locale/en_GB.js'),
    es: async () => import('antd/es/locale/es_ES.js'),
};
const intlPolyfillLocaleData = availableLangs
    .filter((lang) => lang !== Language.en)
    .reduce<LangImport>(
        (acc, lang) => ({
            ...acc,
            [lang]: async () => import(`intl/locale-data/jsonp/${lang}.js`),
        }),
        {},
    );

interface IntlProviderProps {
    children?: React.ReactNode;
}

interface IntlProviderState {
    antLocale?: Locale;
    locale: Language;
    messages: IntlConfig['messages'] | null;
}

/**
 * Intl wrapper around the app
 */
export default class IntlProvider extends React.Component<IntlProviderProps, IntlProviderState> {
    public static contextType = LocaleContext;

    constructor(props: IntlProviderProps) {
        super(props);
        this.state = {
            locale: defaultLanguage,
            messages: null,
        };
        this.setLang();
    }

    public render() {
        const { children } = this.props;
        const { antLocale, locale, messages } = this.state;

        if (this.context.locale !== locale) {
            this.setLang(this.context.locale);
        }

        if (messages === null || this.context.locale !== locale) {
            return (
                <div id="initial-loader">
                    <Spin />
                </div>
            );
        }

        return (
            <ConfigProvider locale={antLocale}>
                <ReactIntlProvider locale={locale} messages={messages} key={locale}>
                    {children}
                </ReactIntlProvider>
            </ConfigProvider>
        );
    }

    private async setLang(lang?: Language) {
        if (!lang) {
            lang = defaultLanguage;
        }

        // Intl polyfill
        if (!Intl) {
            await import('intl');

            await intlPolyfillLocaleData[lang]?.();
        }

        if (shouldPolyfillRelativeTimeFormat()) {
            await import('@formatjs/intl-relativetimeformat/polyfill');
        }
        await reactIntlRelativeLocaleData[lang]?.();

        if (shouldPolyfillPluralRules()) {
            await import('@formatjs/intl-pluralrules/polyfill');
        }
        await reactIntlPluralLocaleData[lang]?.();

        if (shouldPolyfillListFormat()) {
            await import('@formatjs/intl-listformat/polyfill');
        }
        await reactIntlListFormatLocaleData[lang]?.();

        await dayJsLocaleData[lang]?.();
        dayjs.locale(lang);

        await momentLocaleData[lang]?.();
        moment.locale(lang);

        const messages = await l10nFiles[lang]?.();
        const antLocale = await antdLocales[lang]?.();

        apiClient.defaults.headers['x-api-language'] = lang;
        localStorage.setItem('locale', lang);

        this.setState({
            antLocale: antLocale.default,
            locale: lang,
            messages,
        });
    }
}
