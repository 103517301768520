import { Site } from './apiTypes';
import { SearchPaginationQuery } from './';
import api, { cleanPayload } from './_client';
import { RcFile } from 'antd/lib/upload';

export interface SiteDetailsPayload {
    id: string;
}

export interface SiteUploadPayload {
    id: string;
    file: RcFile;
    type: 'logo';
}

export type SiteListPayload = SearchPaginationQuery;

export const details = (payload: SiteDetailsPayload) => api.get(`/cms/sites/${payload.id}`);

export const list = (payload: SiteListPayload = {}) => {
    return api.get(`/cms/sites`, {params: cleanPayload(payload)});
};

export const create = (payload: Site) => {
    return api.post(`/cms/sites`, payload);
};

export const update = (payload: Site) => {
    const id: string = payload.id;
    delete payload.id;
    return api.put(`/cms/sites/${id}`, payload);
};

export const remove = (payload: Site) => {
    return api.delete(`/cms/sites/${payload.id}`);
};

export const upload = (payload: SiteUploadPayload) => {

    const formData = new FormData();
    formData.append('image', payload.file);

    return api.post(`/cms/sites/${payload.id}/${payload.type}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
