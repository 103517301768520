import { hasOwnProp, debug } from './helpers';

export enum RoutePathName {
    users = 'users',
    login = 'login',
    notFound = 'notFound',
    transactions = 'transactions',
    subscriptions = 'subscriptions',
    sites = 'sites',
    privacy = 'privacy',
}

export type Routes = {
    [r in RoutePathName]: string;
};

export const routes: Routes = {
    [RoutePathName.transactions]: '/',
    [RoutePathName.subscriptions]: '/subscriptions',
    [RoutePathName.users]: '/users',
    [RoutePathName.login]: '/login',
    [RoutePathName.notFound]: '/404',
    [RoutePathName.privacy]: '/confidentialite',
    [RoutePathName.sites]: '/sites',
};

export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path eg: /user/:id
export const getRawRoute = (path: RoutePathName) => {
    if (!hasOwnProp(routes, path)) {
        debug.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return '/404';
    } else {
        return routes[path];
    }
};

// returns real-world path eg: /user/1337
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
    anchor?: string,
) => {
    let route = getRawRoute(path);
    const rawParams = route.split('/').filter((str) => str.startsWith(':'));
    const providedParamNames = params ? Object.keys(params) : [];

    rawParams.forEach((rawParam) => {
        const isOptionnal = rawParam.endsWith('?');
        const rawParamStripped = isOptionnal ?
            rawParam.substring(1, rawParam.length - 1) :
            rawParam.substring(1);

        if (params && providedParamNames.includes(rawParamStripped)) {
            route = route.replace(rawParam, `${params[rawParamStripped]}`);
        } else if (isOptionnal) {
            debug.info(`[getRoute] Optionnal param "${rawParamStripped}" not provided in route "${route}", skipping param.`);
            route = route.replace(`/${rawParam}`, '');
        } else {
            throw new Error(`[getRoute] Param "${rawParamStripped}" not provided in route "${route}".`);
        }
    });

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    if (anchor) {
        route = `${route}#${anchor}`;
    }

    return route;
};
