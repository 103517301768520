import { Status, TransactionValidationStatus } from '../store/api/apiTypes';

import { useIntl } from 'react-intl';
import genericMessages from '../locale/genericMessages';
export { default as constants } from '../config/constants';

interface LoggerType {
    isAllowed: boolean;
    log(messages?: any, ...optionalParams: any[]): void;
    warn(messages?: any, ...optionalParams: any[]): void;
}

class Logger implements LoggerType {
    public isAllowed: boolean;

    constructor() {
        this.isAllowed = process.env.NODE_ENV !== 'production';
    }

    public log(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: dodgerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public info(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: cornflowerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public warn(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: darkorange; font-weight: bold', messages, ...optionalParams);
        }
    }

    public error(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: tomato; font-weight: bold', messages, ...optionalParams);
        }
    }
}

export const debug = new Logger();

export const getFullName = (firstname?: string, lastname?: string) => {
    if (!firstname && !lastname) {
        return '';
    }

    return `${firstname || ''}${lastname ? ` ${lastname}` : ''}`.trim();
};

export const capitalize = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.split(' ').map(capitalize).join(' ');
};

export const hasOwnProp = (obj: object, value: string | number | [], strict = true) => {
    let result = false;

    if (typeof obj === 'object') {
        if (Array.isArray(value)) {
            result = strict ?
                !value.some((property) => !Object.prototype.hasOwnProperty.call(obj, property)) :
                value.some((property) => Object.prototype.hasOwnProperty.call(obj, property));
        } else {
            result = Object.prototype.hasOwnProperty.call(obj, value);
        }
    }

    return result;
};

export const addYear = (date: Date) => {
    date.setFullYear(date.getFullYear() + 1);

    return date;
};

export const TranslateTransactionStatus = (value: Status) => {
    const {formatMessage} = useIntl();
    switch (value) {
        case Status.Cancelled: return formatMessage(genericMessages.transactionStatusCancelled);
        case Status.Paid: return  formatMessage(genericMessages.transactionStatusPaid);
        case Status.Unstarted: return formatMessage(genericMessages.transactionStatusUnstarted);
        case Status.Processing: return formatMessage(genericMessages.transactionStatusProcessing);
        case Status.Rejected: return  formatMessage(genericMessages.transactionStatusRejected);
        case Status.InternalError: return  formatMessage(genericMessages.transactionStatusInternalError);
        case Status.NothingToPay: return  formatMessage(genericMessages.transactionStatusNothingToPay);
        case Status.BadgeSent: return  formatMessage(genericMessages.transactionStatusBadgeSent);
        case Status.BadgeNotSent: return  formatMessage(genericMessages.transactionStatusBadgeNotSent);
        default: return value;
    }
};

export const TranslateTransactionValidationStatus = (value: TransactionValidationStatus) => {
    const {formatMessage} = useIntl();

    switch (value) {
        case TransactionValidationStatus.pending: return  formatMessage(genericMessages.transactionValidationStatusPending);
        case TransactionValidationStatus.refused: return  formatMessage(genericMessages.transactionValidationStatusRefused);
        case TransactionValidationStatus.accepted: return  formatMessage(genericMessages.transactionValidationStatusAccepted);
        default: return value;
    }
};

export const downloadFile = (filename: string, data: string, type: string = 'text/csv') => {

    const file = new Blob([data], {type});
    const fileUrl = URL.createObjectURL(file);

    const element = document.createElement('a');
    element.href = fileUrl;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
};

export const isString = (value: any) => {
    return typeof value === 'string' || value instanceof String;
};
