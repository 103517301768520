import axios from 'axios';
import { RequestError } from './';

const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'x-orbi-api-key': process.env.REACT_APP_API_KEY,
        'x-orbi-api-backoffice': true,
    },
    withCredentials: true, // automatically send saved cookie
});

client.interceptors.response.use(
    (response) => response.data,
    (error) => {
        const formattedError: RequestError = {
            status: 0,
            message: '',
        };
        if (error.response) {
            formattedError.status = error.response.status;
            formattedError.data = error.response.data;
            formattedError.headers = error.response.headers;

            if (error.response.status === 401) {
                const e = document.createEvent('CustomEvent');
                e.initCustomEvent('unauthorized.error', true, false, 401);
                window.dispatchEvent(e);
            }
        }

        throw formattedError;
    },
);

export const cleanPayload = (payload: any) => {
    if (!payload.sortBy) { delete payload.sortBy; }
    if (!payload.sort) { delete payload.sort; }
    if (!payload.order) {
        delete payload.sort;
        delete payload.order;
    }
    if (!payload.search) { delete payload.search; }

    if (payload.order === 'ascend') { payload.order = 'asc'; }
    if (payload.order === 'descend') { payload.order = 'desc'; }

    if (payload.filters !== undefined) {
        payload.filters.forEach((filter: any) => {
            payload[filter.name] = filter.value;
        });

        delete payload.filters;
    }

    return payload;
};

export default client;
