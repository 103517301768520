import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Form,  Spin, Divider, Typography, Input, Row, Col, Radio } from 'antd';
import { MainReducerState } from '../../store/reducers';
import { connect } from 'react-redux';
import { BadgeType, ServiceType, Site } from '../../store/api/apiTypes';
import { FormProps } from 'antd/lib/form';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { useIntl, FormattedMessage } from 'react-intl';
import genericMessages from '../../locale/genericMessages';

import {
    SitesState,
    details as siteDetails,
    update as siteUpdate,
    upload as siteUpload,
} from '../../store/actions/sites';
import {
    theme,
} from '../../store/actions/theme';
import ColorPicker from '../../components/ColorPicker';
import { TranslateBadgeType } from '../../helpers/translate';
import UploadFile from '../../components/UploadFile';
import { isString } from '../../helpers';
import PageDrawer from '../../components/PageDrawer';

interface SiteSettingsDrawerProps extends RouteProps {
    id?: Site['id'];
    isVisible: boolean;
    onClose: () => void;
    onSuccess?: () => void;

    sites: SitesState;
    getDetails: typeof siteDetails.trigger;
    detailsReset: typeof siteDetails.reset;
    update: typeof siteUpdate.trigger;
    updateReset: typeof siteUpdate.reset;
    upload: typeof siteUpload.trigger;
    uploadReset: typeof siteUpload.reset;

    setTheme: typeof theme.actions.set;
}

const SiteSettingsDrawer: FC<SiteSettingsDrawerProps> = ({
    id,
    isVisible,
    onClose,
    onSuccess,

    sites,
    getDetails,
    detailsReset,
    update,
    updateReset,
    upload,
    uploadReset,

    setTheme,
}) => {

    const [ formValues, setFormValues ] = useState<any>();
    const site: Site | undefined = sites.details.data;
    const {formatMessage} = useIntl();

    const [form] = Form.useForm();

    useEffect(() => {
        if (isVisible && id) {
            getDetails({id});
        } else {
            detailsReset();
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (sites.details.data) {
            form.setFieldsValue(sites.details.data);
        }
    }, [sites.details.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (sites.update.success) {
            updateReset();
            if (onSuccess) {
                onSuccess();
            }

            if (formValues.logoImage && !isString(formValues.logoImage)) {
                upload({id, file: formValues.logoImage, type: 'logo'});
            }

            if (formValues.loginImage && !isString(formValues.loginImage)) {
                upload({id, file: formValues.loginImage, type: 'loginimage'});
            }

            if (formValues.bannerImage && !isString(formValues.bannerImage)) {
                upload({id, file: formValues.bannerImage, type: 'banner'});
            }
        }
    }, [sites.update.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const onValuesChange: FormProps['onValuesChange'] = (changes, values) => {
        setFormValues(values);
    };

    const onFormFinish: FormProps['onFinish'] = (values) => {

        if (values.logoImage) { delete values.logoImage; }
        if (values.loginImage) { delete values.loginImage; }
        if (values.bannerImage) { delete values.bannerImage; }

        form.validateFields().then(() => {

            setTheme({name: 'color', value: values.themeColor});

            if (id) {
                values.id = id;
                update(values);
            }
        });
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const getPageBySlug = (slug: string) => {
        return site?.pages.find((page) => page.slug === slug);
    };

    // ---------------------------------------
    // Render the page

    return (
        <Drawer
            className="site-drawer"
            title={formatMessage({  id: 'parking.settings',  defaultMessage: 'Paramètres du parking' })}
            width={900}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <Spin spinning={sites.details.loading}>
                {site && (
                    <Form
                        onFinish={onFormFinish}
                        onValuesChange={onValuesChange}
                        layout="vertical"
                        hideRequiredMark
                        form={form}
                        initialValues={site}
                    >
                        <Form.Item
                            label={formatMessage(genericMessages.logo)}
                            name="logoImage"
                        >
                            <UploadFile />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage(genericMessages.themeColor)}
                            name="themeColor"
                        >
                            <ColorPicker initialValue={site?.themeColor} />
                        </Form.Item>

                        {(site?.services && site.services.includes(ServiceType.ppo)) && (
                            <>
                                <Divider />

                                <div className="mb-3">
                                    <Typography.Title level={2} className="text-primary">
                                    <FormattedMessage {...genericMessages.ppoTitle} />
                                    </Typography.Title>
                                </div>

                                <div className="mb-3">
                                    <strong>Paramètres des abonnements</strong>
                                </div>

                                <Form.Item
                                    label={formatMessage({  id: 'parking.max_plates_count',  defaultMessage: 'Nombre maximum de plaques d’immatriculation'})}
                                    name="nbPlates"
                                    rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                >
                                    <Input size="large" />
                                </Form.Item>

                                <Row gutter={16}>
                                    <Col className="gutter-row" span={14}>
                                        <Form.Item
                                            label={formatMessage(genericMessages.badgeType)}
                                            name="badgeType"
                                            rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                        >
                                            <Radio.Group buttonStyle="solid" size="large" className="separate-mobile">
                                                <Radio.Button value={BadgeType.dematerialized}>
                                                    {TranslateBadgeType(BadgeType.dematerialized)}
                                                </Radio.Button>
                                                <Radio.Button value={BadgeType.physical}>
                                                    {TranslateBadgeType(BadgeType.physical)}
                                                </Radio.Button>
                                                <Radio.Button value={BadgeType.dematerializedAndPhysical}>
                                                    {TranslateBadgeType(BadgeType.dematerializedAndPhysical)}
                                                </Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={10}>
                                        <Form.Item
                                            label={formatMessage({  id: 'site.expiration_warning',  defaultMessage: 'Avertissement à l’échéance' })}
                                            name="deadline"
                                            rules={[{ required: true, message: formatMessage(genericMessages.mandatoryField) }]}
                                        >
                                            <Radio.Group buttonStyle="solid" size="large">
                                                <Radio.Button value={true}>
                                                    Oui
                                                </Radio.Button>
                                                <Radio.Button value={false}>
                                                    Non
                                                </Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <div className="mb-2">
                                    <strong>Paramètres du site internet</strong>
                                </div>

                                <Form.Item
                                    label={formatMessage({  id: 'site.photo_header',  defaultMessage: 'Photo header (optionnelle)' })}
                                    name="bannerImage"
                                >
                                    <UploadFile />
                                </Form.Item>

                                <Form.Item
                                    label={formatMessage({  id: 'site.login_photo',  defaultMessage: 'Photo login/inscription (optionnelle)'})}
                                    name="loginImage"
                                >
                                    <UploadFile />
                                </Form.Item>

                                {isVisible && (
                                    <Form.Item
                                        label={formatMessage({  id: 'site.footer_page_content',  defaultMessage:  'Contenus pages du footer' })}
                                        className="pages-list"
                                    >
                                        <Form.Item
                                            name="privacyPolicy"
                                        >
                                            <PageDrawer initialValue={getPageBySlug('privacyPolicy')} label={formatMessage(genericMessages.sitePrivacyPolicy)} />
                                        </Form.Item>

                                        <Form.Item
                                            name="cgv"
                                        >
                                            <PageDrawer initialValue={getPageBySlug('cgv')} label={formatMessage(genericMessages.siteSalesConditions)}/>
                                        </Form.Item>

                                        <Form.Item
                                            name="cgu"
                                        >
                                            <PageDrawer initialValue={getPageBySlug('cgu')} label={formatMessage(genericMessages.siteUsageRule)}/>
                                        </Form.Item>

                                        <Form.Item
                                            name="legalNotice"
                                        >
                                            <PageDrawer initialValue={getPageBySlug('legalNotice')} label={formatMessage(genericMessages.siteLegalNotice)}/>
                                        </Form.Item>
                                    </Form.Item>
                                )}

                            </>
                        )}

                        <Form.Item className="cta-submit">
                            <Button
                                type="primary"
                                size="large"
                                shape="round"
                                block
                                htmlType="submit"
                                loading={sites.update.loading}
                            >
                               <FormattedMessage {...genericMessages.saveChanges} />
                            </Button>
                        </Form.Item>

                    </Form>
                )}
            </Spin>
        </Drawer>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    sites: state.sites,
});

export default connect(
    mapStateToProps,
    {
        getDetails: siteDetails.trigger,
        detailsReset: siteDetails.reset,
        update: siteUpdate.trigger,
        updateReset: siteUpdate.reset,
        setTheme: theme.actions.set,
        upload: siteUpload.trigger,
        uploadReset: siteUpload.reset,
    },
)(SiteSettingsDrawer);
