import api from './_client';

export interface LoginPayload {
    username: string;
    password: string;
}

export const login = (payload: LoginPayload) => api.post('/cms/adminLogin', payload);

export const logout = () => api.post('/cms/logout');

export const checkLoginStatus = () => api.get('/cms/me');
