import { defineMessages } from 'react-intl';

const messages = defineMessages({
    yes: {
        id: 'yes',
        defaultMessage: 'Oui',
    },
    error: {
        id: 'error',
        defaultMessage: 'Erreur',
    },
    error_try_again: {
        id: 'error_try_again',
        defaultMessage: 'Nous sommes désolés, une erreur est survenue, veuillez réessayer plus tard ou contacter le support.',
    },
    no: {
        id: 'no',
        defaultMessage: 'Non',
    },
    or: {
        id: 'or',
        defaultMessage: 'ou',
    },
    validate: {
        id: 'validate',
        defaultMessage: 'Valider',
    },
    edit: {
        id: 'edit',
        defaultMessage: 'Modifier',
    },
    cancel: {
        id: 'cancel',
        defaultMessage: 'Annuler',
    },
    delete: {
        id: 'delete',
        defaultMessage: 'Supprimer',
    },
    enable:
    {
        id: 'enable',
        defaultMessage: 'Activer',
    },
    confirm:
    {
        id: 'confirm',
        defaultMessage: 'Confirmer',
    },
    disable:
    {
        id: 'disable',
        defaultMessage: 'Désactiver',
    },
    stationnement: {
        id: 'stationnemnet',
        defaultMessage: 'Stationnement',
    },
    parking: {
        id: 'parking',
        defaultMessage: 'Parking',
    },
    parkingSearch: {
        id: 'parking.search',
        defaultMessage: 'Rechercher un parking',
    },
    site: {
        id: 'site',
        defaultMessage: 'Site',
    },
    siteUsageRule: {
        id: 'site.usage_rules',
        defaultMessage: 'Conditions générales d’utilisation',
    },
    siteSalesConditions: {
        id: 'site.sales_conditions',
        defaultMessage: 'Conditions générales de vente',
    },
    sitePrivacyPolicy: {
        id: 'site.privacy_policy',
        defaultMessage: 'Politique de confidentialité',
    },
    siteLegalNotice: {
        id: 'site.legal_notes',
        defaultMessage: 'Mentions légales',
    },
    siteLoginPhoto: {
        id: 'site.login_photo',
        defaultMessage: 'Photo login/inscription (optionnelle)',
    },
    sitePhotoHeader: {
        id: 'site.photo_header',
        defaultMessage: 'Photo header (optionnelle)',
    },
    siteFooterContent: {
        id: 'site.footer_page_content',
        defaultMessage: 'Contenus pages du footer',
    },
    siteCommercialName: {
        id: 'site.commercial_name',
        defaultMessage: 'Nom commercial',
    },
    phone: {
        id: 'phone',
        defaultMessage: 'Téléphone',
    },
    actions: {
        id: 'actions',
        defaultMessage: 'Actions',
    },
    confirmDelete: {
        id: 'confirm_delete',
        defaultMessage: 'Confirmez-vous la suppression ?',
    },
    userRoleCustomer: {
        id: 'user.role.customer',
        defaultMessage: 'Client',
    },
    userRoleAdmin: {
        id: 'user.role.admin',
        defaultMessage: 'Admin',
    },
    userRoleSuperAdmin: {
        id: 'user.role.super_admin',
        defaultMessage: 'Super Admin',
    },
    userRoleSite: {
        id: 'user.role.site',
        defaultMessage: 'site',
    },
    resetPassword: {
        id: 'password.reset',
        defaultMessage: 'Réinitialisation du mot de passe',
    },
    siteToken: {
        id: 'site.token',
        defaultMessage: 'Token',
    },
    resetPasswordOk: {
        id: 'password.reset_ok',
        defaultMessage: 'Le mot de passe de cet utilisateur a bien été réinitialisé.',
    },
    resetPasswordFailed: {
        id: 'password.reset_failed',
        defaultMessage: 'Une erreur est survenue et le mot de passe de cet utilisateur n\'a pas été réinitialisé.',
    },
    mandatoryField: {
        id: 'mandatory_field',
        defaultMessage: 'Ce champ est obligatoire',

    },
    userLastName: {
        id: 'user.last_name',
        defaultMessage: 'Nom',
    },
    userFirstName: {
        id: 'user.first_name',
        defaultMessage: 'Prénom',
    },
    userEmail: {
        id: 'user.email',
        defaultMessage: 'Email',
    },
    userCreate: {
        id: 'user.create',
        defaultMessage: 'Créer un utilisateur',
    },
    receipt: {
        id: 'receipt',
        defaultMessage: 'Reçu',
    },
    status: {
        id: 'status',
        defaultMessage: 'Statut',
    },
    amount: {
        id: 'amount',
        defaultMessage: 'Montant',
    },
    payment: {
        id: 'payment',
        defaultMessage: 'Paiement',
    },
    transaction: {
        id: 'transaction',
        defaultMessage: 'Transaction',
    },
    transactionStatusCancelled: {
        id: 'transaction.status.cancelled',
        defaultMessage: 'Annulée',
    },
    transactionStatusPaid: {
        id: 'transaction.status.paid',
        defaultMessage: 'Payée',
    },
    transactionStatusUnstarted: {
        id: 'transaction.status.unstated',
        defaultMessage: 'Initialisée',
    },
    transactionStatusProcessing: {
        id: 'transaction.status.processing',
        defaultMessage: 'En cours',
    },
    transactionStatusRejected: {
        id: 'transaction.status.rejected',
        defaultMessage: 'Refusée',
    },
    transactionStatusInternalError: {
        id: 'transaction.status.internalError',
        defaultMessage: 'Erreur PSP',
    },
    transactionStatusNothingToPay: {
        id: 'transaction.status.nothing_to_pay',
        defaultMessage: 'Transaction gratuite',
    },
    transactionStatusBadgeSent: {
        id: 'transaction.status.badge_sent',
        defaultMessage: 'Envoyé',
    },
    transactionStatusBadgeNotSent: {
        id: 'transaction.status.badge_not_sent',
        defaultMessage: 'Attente envoi',
    },
    transactionValidationStatusPending: {
        id: 'transaction.validationsatus.pending',
        defaultMessage: 'À valider',
    },
    transactionValidationStatusRefused: {
        id: 'transaction.validationsatus.refused',
        defaultMessage: 'Refusée',
    },
    transactionValidationStatusAccepted: {
        id: 'transaction.validationsatus.accepted',
        defaultMessage: 'Acceptée',
    },
    transactionsSubscriptions: {
        id: 'transactions.subscriptions',
        defaultMessage: 'Abonnements',
    },
    transactionsParkings: {
        id: 'transactions.parking',
        defaultMessage: 'Stationnements',
    },
    exportData: {
        id: 'data.export',
        defaultMessage: 'Exporter les données',
    },
    transactionDate: {
        id: 'transaction.date',
        defaultMessage: 'Date de la transaction',
    },
    transactionsHistory: {
        id: 'transactions.history',
        defaultMessage: 'Historique des transactions',
    },
    subscriptionPaymentForm: {
        id: 'subscription.payment_form',
        defaultMessage: 'Moyen de paiement',
    },
    paylineId: {
        defaultMessage: 'ID Payline',
        id: 'subscription.payline_id',
    },
    paylineStatus: {
        id: 'subscription.payline_status',
        defaultMessage: 'Statut Payline',
    },
    nmiStatus: {
        id: 'subscription.nmi_status',
        defaultMessage: 'Statut NMI',
    },
    nmmiId: {
        id: 'subscription.nmi_id',
        defaultMessage: 'ID NMI',
    },
    headerSites: {
        id: 'header.sites',
        defaultMessage: 'Sites',
    },
    headerTransactions: {
        id: 'header.transactions',
        defaultMessage: 'Transactions',
    },
    headerUsers: {
        id: 'header.users',
        defaultMessage: 'Utilisateurs',
    },
    siteServices: {
        id: 'site.services',
        defaultMessage: 'Services',
    }
    ,
    themeColor: {
        id: 'parking.theme_color',
        defaultMessage: 'Couleur du thème',
    }
    ,
    logo: {
        id: 'parking.logo',
        defaultMessage: 'Logo',
    },
    ppoTitle: {
        id: 'ppo.title',
        defaultMessage: 'Park Pass Online',
    },
    siteConfirmDisable: {
        id: 'sites.confirm_disable',
        defaultMessage: 'Confirmez-vous la désactivation ?',
    },
    siteConfirmEnable: {
        id: 'sites.confirm_enable',
        defaultMessage: 'Confirmez-vous l\'activation ?',
    },
    siteShowTransactions: {
        id: 'sites.showTransactions',
        defaultMessage: 'Voir les transactions',
    },
    civilityMissus: {
        id: 'civility.missus',
        defaultMessage: 'Mme',
    },
    civilityMister: {
        id: 'civility.mister',
        defaultMessage: 'Mr',
    },
    badgeTypeDematerializedAndPhysical: {
        id: 'badge_type.dematerializedAndPhysical',
        defaultMessage: 'Physique + dématérialisé',
    },
    badgeTypePhysical: {
        id: 'badge_type.physical',
        defaultMessage: 'Physique',
    },
    badgeTypeDematerialized: {
        id: 'badge_type.dematerialized',
        defaultMessage: 'Dématérialisé',
    },
    userTypeCompany: {
        id: 'user_type.company',
        defaultMessage: 'Entreprise',
    },
    userTypeIndividual: {
        id: 'user_type.individual',
        defaultMessage: 'Particulier',
    },
    serviceTypePayExt: {
        id: 'service_type.pay_ext',
        defaultMessage: 'Web Payment Solution',
    },
    serviceTypePpo: {
        id: 'service_type.ppo',
        defaultMessage: 'Park Pass Online',
    },
    pspNamePayline: {
        id: 'psp.name.payline',
        defaultMessage: 'Payline',
    },
    pspNameNmi: {
        id: 'psp.name.nmi',
        defaultMessage: 'NMI',
    },
    pspNameAdvam: {
        id: 'psp.name.advam',
        defaultMessage: 'Advam',
    },
    pspNameGlobalPayment: {
        id: 'psp.name.globalpayment',
        defaultMessage: 'Global Payment',
    },
    saveChanges: {
        id: 'save_changes',
        defaultMessage: 'Enregistrer les modifications',
    },
    userRole: {
        id: 'user.role',
        defaultMessage: 'Rôle',
    },
    siteSearch: {
        id: 'site.search',
        defaultMessage: 'Recherchez un site',
    },

    userSelectRole: {
        id: 'user.selectRole',
        defaultMessage: 'Sélectionner un rôle',
    },
    ticket: {
        id: 'ticket',
        defaultMessage: 'Ticket',
    },
    ticketSearch: {
        id: 'ticket.search',
        defaultMessage: 'Rechercher un n° de ticket, un id de paiement...',
    },
    date: {
        id: 'date',
        defaultMessage: 'Date',
    },
    cardNumber: {
        id: 'card_number',
        defaultMessage: 'N° de carte(s)',
    },
    validity: {
        id: 'validity',
        defaultMessage: 'Validité',
    },
    userCreationDate: {
        id: 'user.creation_date',
        defaultMessage: 'Date de création',
    },
    shipment: {
        id: 'shipment',
        defaultMessage: 'Envoi',
    },
    transactionSearchPlaceholder: {
        id: 'transactions.search_placeholder',
        defaultMessage: 'Rechercher un client, un n° de carte...',
    },
    transactionValidityPeriod: {
        id: 'transaction.validity_period',
        defaultMessage: 'Période de validité',
    },
    transactionValidityPeriodStart: {
        id: 'transaction.validity_period.start',
        defaultMessage: 'Date de début',
    },
    transactionValidityPeriodEnd: {
        id: 'transaction.validity_period.end',
        defaultMessage: 'Date de fin',
    },
    transactionInDate: {
        id: 'transaction.in_date',
        defaultMessage: 'Date d\'entrée',
    },
    transactionOutDate: {
        id: 'transaction.out_date',
        defaultMessage: 'Date de sortie',
    },
    transactionEnterComment: {
        id: 'transaction.enter_comment',
        defaultMessage: 'Saisir votre commentaire',
    },
    errorUnauthorised: {
        id: 'error.unauthorised',
        defaultMessage: 'Non autorisé',
    },
    errorUnknownUser: {
        id: 'error.unknown_user',
        defaultMessage: 'Nom d\'utilisateur introuvable',
    },
    transactionServerQueryTime: {
        id: 'transaction.server_query_time',
        defaultMessage: 'Heure de la requête serveur',
    },
    priceTotalIncludingTaxes: {
        id: 'subscription.total_with_taxes',
        defaultMessage: 'Total TTC',
    },
    priceTotalExcludingTaxes: {
        id: 'subscription.total_without_taxes',
        defaultMessage: 'Total HT',
    },
    paymentStatus: {
        id: 'transaction.payment_status',
        defaultMessage: 'Statut du paiement',
    },
    type: {
        id: 'type',
        defaultMessage: 'Type',
    },
    badgeType: {
        id: 'badge.type',
        defaultMessage: 'Type de badge',
    }
    ,
    apiToken: {
        id: 'site.api_token',
        defaultMessage: 'Token API',
    },
    siteId: {
        id: 'site.id',
        defaultMessage: 'Site ID',
    }
    ,
    siteCode: {
        id: 'site.code',
        defaultMessage: 'Site Code',
    },
    siteLogin: {
        id: 'site.login',
        defaultMessage: 'Login',
    },
    pspType: {
        id: 'psp.type',
        defaultMessage: 'Type PSP',
    },
    userSearch: {
        id: 'user.search',
        defaultMessage: 'Rechercher un utilisateur',
    }
    ,
    pspContractNumber: {
        id: 'contract_number',
        defaultMessage: 'Numéro de contrat',
    },
    secretKey: {
        id: 'secret_key',
        defaultMessage: 'Clé secrète',
    },
    siteExpirationWarning: {
        id: 'site.expiration_warning',
        defaultMessage: 'Avertissement à l\'échéance',
    },
    siteEdit: {
        id: 'site.action_edit',
        defaultMessage: 'Editer le site',
    },
    siteAdd: {
        id: 'site.action_add',
        defaultMessage: 'Ajouter un site',
    },
    advamMessage: {
        id: 'site.advam_message',
        defaultMessage: 'Veuillez vous connecter à <LINK>l\'interface d\'administration d\'Advam</LINK> afin de procéder au remboursement de la transaction numéro :<ORDER_NUMBER></ORDER_NUMBER>',
    },
});

export default messages;
