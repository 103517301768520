import React, { FC, ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { useIntl, FormattedMessage } from 'react-intl';
import genericMessages from '../../locale/genericMessages';

import {
    Table,
    Button,
    Badge,
    DatePicker,
    notification,
    Menu,
    Tag,
    Switch,
    Modal,
    Input,
    Space,
    Typography,
    Row,
} from 'antd';

import { Transaction, Site, Status, ServiceType, BadgeInfo, TransactionValidationStatus, PSPName } from '../../store/api/apiTypes';
import { MainReducerState } from '../../store/reducers';
import Search from 'antd/lib/input/Search';
import SubscriptionDrawer from './SubscriptionDrawer';
import {
    TransactionsState,
    list as transactionsList,
    exportTransactions as transactionsExport,
    invoice as transactionsInvoice,
    deliveryForm as transactionsDeliveryForm,
    validate as transactionValidate,
} from '../../store/actions/transactions';

import { FilterQuery } from '../../store/api';
import { DownloadOutlined, ExclamationCircleOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import Seo from '../../components/Seo';
import { downloadFile, TranslateTransactionStatus, TranslateTransactionValidationStatus } from '../../helpers';
import moment from 'moment';
import SiteFilterSelect from '../sites/SiteFilterSelect';
import TagTransactionStatus from '../../components/TagTransactionStatus';
import Price from '../../components/Price';
import { Link, useLocation } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import TagTransactionValidationStatus from '../../components/TagTransactionValidationStatus';
import { usePrevious } from '../../hooks';

const rowKey = (item: Transaction) => `${item.id}`;
const advamUrl = process.env.REACT_APP_ADVAM_MERCHANT_URL;
interface SubscriptionsListProps {
    transactions: TransactionsState;
    getList: typeof transactionsList.trigger;
    exportTransactions: typeof transactionsExport.trigger;
    exportTransactionsReset: typeof transactionsExport.reset;
    getInvoice: typeof transactionsInvoice.trigger;
    getInvoiceReset: typeof transactionsInvoice.reset;
    getDeliveryFormReset: typeof transactionsDeliveryForm.reset;
    getValidateTransaction: typeof transactionValidate.trigger;
}

interface ValidationData {
    transactionId?: string;
    validationAccepted?: boolean;
    validationComment?: string;
}

const SubscriptionsList: FC<SubscriptionsListProps> = ({
    transactions,
    getList,
    exportTransactions,
    exportTransactionsReset,
    getInvoice,
    getInvoiceReset,
    getDeliveryFormReset,
    getValidateTransaction,
}) => {

    const location = useLocation();
    const { formatMessage } = useIntl();
    const itemsPerPage: number = 20;
    const [selectedId, setSelectedId] = useState<string | undefined>();
    const [validation, setValidation] = useState<ValidationData>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isAdvamModalVisible, setIsAdvamModalVisible] = useState(false);
    const [validationComment, setValidationComment] = useState<string>('');
    // const [ search, setSearch ] = useState<string>();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [lastSearchParams, setLastSearchParams] = useState<any>({});
    const { RangePicker } = DatePicker;

    const previous = usePrevious({
        transactions,
    });

    // ---------------------------------------
    // Query params

    const urlParams = new URLSearchParams(window.location.search);
    const siteQueryParam = urlParams.get('site');

    // ---------------------------------------
    // Drawer management

    useEffect(() => {
        setLastSearchParams({
            ...lastSearchParams,
            sort: 'createdAt',
            order: 'desc',
            page: 0,
            pageSize: itemsPerPage,
            transactionType: ServiceType.ppo,
            search: siteQueryParam || undefined,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearch = (value: string) => {
        setLastSearchParams({
            ...lastSearchParams,
            search: value,
            page: 0,
        });
    };

    useEffect(() => {
        if (lastSearchParams) { getList({ ...lastSearchParams }); }
    }, [lastSearchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    const setSearchParam = (name: string, value: any) => {
        setLastSearchParams({
            ...lastSearchParams,
            [name]: value,
        });
    };

    const setSearchParams = (params: any) => {
        setLastSearchParams({
            ...lastSearchParams,
            ...params,
        });
    };

    const onTableChange: TableProps<Transaction>['onChange'] = (pagination, tableFilters, sorter: any) => {

        const filters: FilterQuery['filters'] = [];

        if (tableFilters.status && tableFilters.status.length > 0) {
            filters.push({
                name: 'status',
                value: tableFilters.status,
            });
        }

        const newSearchParams = {
            ...lastSearchParams,
            page: (pagination.current || 1) - 1,
            pageSize: pagination.pageSize || itemsPerPage,
            sort: (sorter.field) ? sorter.field : undefined,
            order: (sorter.order) ? sorter.order : undefined,
            filters,
        };

        setLastSearchParams(newSearchParams);
    };

    const onDateChange = (dates: any) => {
        if (dates) {
            setSearchParams({
                startDate: moment(dates[0]).toDate(),
                endDate: moment(dates[1]).toDate(),
            });
        } else {
            setSearchParams({
                startDate: undefined,
                endDate: undefined,
            });
        }
    };

    const onValidationStatusChange = (status: boolean) => {
        if (status) {
            setSearchParams({
                validationStatus: TransactionValidationStatus.pending,
            });
        } else {
            setSearchParams({
                validationStatus: undefined,
            });
        }
    };

    // ---------------------------------------
    // Drawer management

    const edit = (id: string) => {
        setSelectedId(id);
        setIsDrawerVisible(true);
    };

    const onDrawserClose = (refresh?: boolean) => {
        setIsDrawerVisible(false);
        if (refresh) {
            getList({ ...lastSearchParams });
        }
    };

    const onDrawerSuccess = () => {
        getList({ ...lastSearchParams });
        setIsDrawerVisible(false);
    };

    // ---------------------------------------
    // Export current search to CSV

    const onExport = () => {
        exportTransactions(lastSearchParams);
    };

    useEffect(() => {
        if (transactions.export.success && transactions.export.data) {
            downloadFile(`export_${moment().format('YYYY/MM/DD-HH:mm')}.csv`, transactions.export.data);
            exportTransactionsReset();
        }
    }, [transactions.export.success]); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // Filters

    const onSiteFilterChange = (id: Site['id']) => {
        setSearchParam('site', id);
    };

    const onParkingFilterChange = (searchParking: string) => {
        setSearchParam('parkingName', searchParking);
    };

    // ---------------------------------------
    // Invoices

    const invoice = (id: Transaction['id']) => {
        getInvoice({ id });
    };

    useEffect(() => {
        if (transactions.invoice.success && transactions.invoice.data) {
            const fileName = `receipt_${transactions.invoice.payload.id}.pdf`;
            downloadFile(fileName, transactions.invoice.data, 'application/pdf');
            getInvoiceReset();
        }

        if (transactions.invoice.error) {
            notification.error({
                message: formatMessage(genericMessages.receipt),
                description: formatMessage({id: 'receipt.downloadError', defaultMessage: 'Une erreur est survenue lors de la récupération du reçu.'}),
            });
            getInvoiceReset();
        }
    }, [transactions.invoice.success, transactions.invoice.error]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (transactions.deliveryForm.success && transactions.deliveryForm.data) {
            const fileName = `delivery_form_${transactions.deliveryForm.payload.id}.pdf`;
            downloadFile(fileName, transactions.deliveryForm.data, 'application/pdf');
            getDeliveryFormReset();
        }

        if (transactions.deliveryForm.error) {
            notification.error({
                message: formatMessage(genericMessages.receipt),
                description: formatMessage({id: 'deliveryForm.downloadError', defaultMessage: 'Une erreur est survenue lors de la récupération du bon de livraison.'}),
            });
            getDeliveryFormReset();
        }
    }, [transactions.deliveryForm.success, transactions.deliveryForm.error]); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // Table columns

    const columns: Array<ColumnProps<Transaction>> = [
        {
            dataIndex: 'createdAt',
            title: formatMessage(genericMessages.date),
            sorter: true,
            defaultSortOrder: 'descend',
            render: (date) => moment(date).format('DD/MM/YYYY - HH:mm'),
        },
        {
            dataIndex: 'validationStatus',
            title: formatMessage(genericMessages.status),
            render: (text, record) => (
                <TagTransactionValidationStatus value={record.validationStatus} />
            ),
            filterMultiple: false,
            filters: [
                { text: TranslateTransactionValidationStatus(TransactionValidationStatus.pending), value: TransactionValidationStatus.pending },
                { text: TranslateTransactionValidationStatus(TransactionValidationStatus.refused), value: TransactionValidationStatus.refused },
                { text: TranslateTransactionValidationStatus(TransactionValidationStatus.accepted), value: TransactionValidationStatus.accepted },
            ],
        },
        {
            title: formatMessage(genericMessages.site),
            key: 'site',
            dataIndex: 'site',
            ellipsis: true,
            render: (site) => site.name,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div className="filter-container">
                    <SiteFilterSelect
                        onChange={onSiteFilterChange}
                        size="middle"
                    />
                </div>
            ),
        },
        {
            dataIndex: 'parkingName',
            title: formatMessage(genericMessages.parking),
            key: 'parkingName',
            ellipsis: true,
            filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div className="filter-container">
                    <Search
                        placeholder={formatMessage(genericMessages.parkingSearch)}
                        onSearch={onParkingFilterChange}
                        loading={transactions.list.loading}
                        allowClear
                        size="middle"
                    />
                </div>
            ),
        },
        {
            title: formatMessage(genericMessages.cardNumber),
            ellipsis: true,
            render: (site) => (
                <>
                    {site.badges.map((badge: BadgeInfo) => (
                        <Tag key={badge.titleNumber}>{badge.titleNumber}</Tag>
                    ))}
                </>
            ),
        },
        {
            title: formatMessage(genericMessages.validity),
            render: (transaction: Transaction) => {
                const offerPrice = (transaction?.titles && transaction.titles.length > 0) ? transaction.titles[0].offerPrice : undefined;

                return offerPrice ? (
                    <>
                        <div>{moment(offerPrice.beginValidityDate).format('DD/MM/YYYY')}</div>
                        <div>{moment(offerPrice.endValidityDate).format('DD/MM/YYYY')}</div>
                    </>
                ) : <></>;
            },
        },
        {
            title: formatMessage(genericMessages.amount),
            key: 'totalDueAmount',
            dataIndex: 'totalDueAmount',
            sorter: true,
            render: (totalDueAmount, transaction) => (
                <Price value={transaction.totalDueAmount} currency={transaction.currencyIso} currencyCentsDigits={transaction.currencyCentsDigits} />
            ),
        },
        {
            dataIndex: 'status',
            title: formatMessage(genericMessages.payment),
            render: (text, record) => (
                <TagTransactionStatus value={record.paymentStatus} />
            ),
            filterMultiple: false,
            filters: [
                { text: TranslateTransactionStatus(Status.Pending), value: Status.Pending },
                { text: TranslateTransactionStatus(Status.Cancelled), value: Status.Cancelled },
                { text: TranslateTransactionStatus(Status.Paid), value: Status.Paid },
                { text: TranslateTransactionStatus(Status.Unstarted), value: Status.Unstarted },
                { text: TranslateTransactionStatus(Status.Processing), value: Status.Processing },
                { text: TranslateTransactionStatus(Status.Rejected), value: Status.Rejected },
                { text: TranslateTransactionStatus(Status.InternalError), value: Status.InternalError },
            ],
        },
        {
            dataIndex: 'status',
            title: formatMessage({id: 'shipment', defaultMessage: 'Envoi'}),
            render: (text, record) => (
                <TagTransactionStatus value={(record.sendBadges) ? Status.BadgeSent : Status.BadgeNotSent} />
            ),
            filterMultiple: false,
            filters: [
                { text: TranslateTransactionStatus(Status.BadgeSent), value: true },
                { text: TranslateTransactionStatus(Status.BadgeNotSent), value: false },
            ],
        },
        {
            title:  formatMessage(genericMessages.actions),
            key: 'actions',
            fixed: 'right',
            width: 110,
            render: (text, record) => (
                <>
                    <Button
                        icon={<EyeOutlined />}
                        onClick={edit.bind(null, record.id)}
                        shape="circle"
                    />
                    {record.paymentStatus === Status.Paid && (
                        <>
                            &nbsp;
                            &nbsp;
                            <Button
                                icon={<DownloadOutlined />}
                                onClick={invoice.bind(null, record.id)}
                                shape="circle"
                            />
                        </>
                    )}
                </>
            ),
        },

    ];

    // Modal management
    const handleChangeValidationComment = (e: React.FormEvent<HTMLInputElement>) => {
        setValidationComment(e.currentTarget.value);
    };

    const setValidationData = (transactionId: string, validationAccepted: boolean) => {
        setValidation({ ...validation, transactionId, validationAccepted });
        setValidationComment('');
        setIsDrawerVisible(false);
        if (!validationAccepted) {
            setIsModalVisible(true);
        }
    };

    const handleModalCancel = () => {
        setValidation({});
        setIsModalVisible(false);
    };

    const addCommentToValidationData = () => {
        setValidation({ ...validation, validationComment });
    };

    useEffect(() => {
        if (validation?.transactionId && (validation.validationAccepted || validation.validationComment)) {
            // request api to update the transaction
            const validationStatus = validation.validationAccepted ? TransactionValidationStatus.accepted : TransactionValidationStatus.refused;
            getValidateTransaction({
                id: validation.transactionId,
                validationStatus,
                validationComment: validation.validationComment,
            });
            setIsModalVisible(false);
            setValidationComment('');
            setValidation({});
        }
    }, [validation]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (previous?.transactions.validate.loading && !transactions.validate.loading) {
            getList({ ...lastSearchParams });
            if (transactions.validate.data?.psp === PSPName.advam && transactions.validate.data.validationStatus === TransactionValidationStatus.refused) {
                setIsAdvamModalVisible(true);
            }
        }
    }, [previous, transactions]); // eslint-disable-line react-hooks/exhaustive-deps

    const pendingTransactions: Transaction[] = transactions.list.data.items.filter((value) => value.validationStatus === TransactionValidationStatus.pending);

    const closeAdvamModal = () => {
        setIsAdvamModalVisible(false);
    };

    return (
        <>
            <Seo title="Transactions" />

            <div className="page-header justify-start">
                <h1 className="page-title">
                    <FormattedMessage {...genericMessages.transactionsHistory}/>
                    <Badge count={transactions.list.data.totalCount} overflowCount={100000} />
                </h1>

                <Menu
                    mode="horizontal"
                    className="main-menu"
                    defaultSelectedKeys={[location.pathname]}
                    selectedKeys={[location.pathname]}
                >
                    <Menu.Item key={getRoute(RoutePathName.transactions)}>
                        <Link to={getRoute(RoutePathName.transactions)}>
                            <span>
                                <FormattedMessage {...genericMessages.transactionsParkings}/>
                            </span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={getRoute(RoutePathName.subscriptions)}>
                        <Link to={getRoute(RoutePathName.subscriptions)}>
                            <span>
                                <FormattedMessage {...genericMessages.transactionsSubscriptions}/>
                            </span>
                        </Link>
                    </Menu.Item>
                </Menu>
            </div>

            <div className="page-search-filters">
                <div className="page-filters" style={{ marginRight: '2em' }}>
                    <Space>
                        <Switch
                            onChange={onValidationStatusChange}
                        />
                        <span>
                            <Typography.Text strong={true} style={{ fontSize: '18px' }}>
                                <FormattedMessage
                                    id="transaction.pending_validation_count" // obligatoire
                                    defaultMessage={
                                        `{count, plural,
            =0 {Aucune transaction à valider }
            one {{count, number} transaction à valider}
            other {{count, number} transactions à valider}
        }`
                                    }
                                    description="signature author"
                                    values={{
                                        count: pendingTransactions.length,
                                    }}
                                />
                            </Typography.Text>
                        </span>
                    </Space>
                </div>
                <Search
                    className="page-search"
                    placeholder={formatMessage(genericMessages.transactionSearchPlaceholder)}
                    defaultValue={siteQueryParam || ''}
                    loading={transactions.list.loading}
                    onSearch={onSearch}
                    allowClear
                    size="large"
                />

                <div className="page-filters">
                    <span>
                        <FormattedMessage {...genericMessages.transactionValidityPeriod}/>
                    </span>
                    <RangePicker
                        onChange={onDateChange}
                        placeholder={[formatMessage(genericMessages.transactionValidityPeriodStart), formatMessage(genericMessages.transactionValidityPeriodEnd)]}
                        size="large"
                    />
                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        onClick={onExport}
                    >
                       <FormattedMessage {...genericMessages.exportData} />
                    </Button>
                </div>
            </div>

            {transactions.list ? (
                <Table<Transaction>
                    className="page-table"
                    rowKey={rowKey}
                    columns={columns}
                    loading={transactions.list.loading}
                    dataSource={transactions.list.data.items}
                    pagination={{
                        total: transactions.list.data.totalCount,
                        current: transactions.list.data.page + 1,
                        pageSize: transactions.list.data.pageSize,
                    }}

                    onChange={onTableChange}
                    scroll={{ x: 1400 }}
                />
            ) : undefined}

            <SubscriptionDrawer
                id={selectedId}
                isVisible={isDrawerVisible}
                setValidationData={setValidationData}
                onClose={onDrawserClose}
                onSuccess={onDrawerSuccess}
            />
            <Modal className="transaction-drawer" visible={isModalVisible} okText="Confirmer" onCancel={handleModalCancel} onOk={addCommentToValidationData} closable={false}>
                <Row className="gutter-row">
                    <Space>
                        <ExclamationCircleOutlined style={{ color: '#FE4F64', fontSize: '2em' }} />
                        <Typography.Title level={3}><FormattedMessage id="transaction.cancel" defaultMessage="Annulation de la transaction" />
                            </Typography.Title>
                    </Space>
                </Row>
                <Row className="gutter-row">
                    <div className="info-row">
                        <Typography.Text strong={true}>
                            <FormattedMessage id="transaction.reject" defaultMessage="Êtes-vous sûr de vouloir refuser cette transaction ?" />
                            </Typography.Text>
                    </div>
                </Row>
                <Row className="gutter-row">
                    <div className="info-row" style={{ marginBottom: 0 }}>
                        <label><FormattedMessage id="transaction.comment" defaultMessage="Commentaire" /></label>
                    </div>
                </Row>
                <Row className="gutter-row">
                    <Input
                        placeholder={formatMessage(genericMessages.transactionEnterComment)}
                        value={validationComment}
                        onChange={handleChangeValidationComment}
                    />
                </Row>
            </Modal>
            <Modal
                className="transaction-drawer"
                visible={isAdvamModalVisible}
                onOk={closeAdvamModal}
                closable={false}
                footer={[(
                    <Button key="submit" type="primary" onClick={closeAdvamModal}>
                        <FormattedMessage {...genericMessages.confirm}/>
                    </Button>
                )]}
            >
                <Row className="gutter-row">
                    <FormattedMessage
                        {
                            ...genericMessages.advamMessage
                        }
                        values={{
                            LINK: (chunk: ReactNode) => (
                                <a href={advamUrl}>{chunk}</a>
                            ),
                            ORDER_NUMBER: () => (
                                transactions.validate.data?.id
                            ),
                        }}
                    />
                </Row>
            </Modal>
        </>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    transactions: state.transactions,
});

export default connect(
    mapStateToProps,
    {
        getList: transactionsList.trigger,
        exportTransactions: transactionsExport.trigger,
        exportTransactionsReset: transactionsExport.reset,
        getInvoice: transactionsInvoice.trigger,
        getInvoiceReset: transactionsInvoice.reset,
        getDeliveryFormReset: transactionsDeliveryForm.reset,
        getValidateTransaction: transactionValidate.trigger,
    },
)(SubscriptionsList);
