import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { User, ListResponse, Role } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import {
    UserDetailsPayload,
    UserListPayload,
    details as detailsApiCall,
    list as listApiCall,
    roles as rolesApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
    resetPassword as resetPasswordApiCall,
} from '../api/users';

// State

export interface UsersState {
    list: RequestState<ListResponse<User>>;
    roles: RequestState<ListResponse<Role>>;
    details: RequestState<User | undefined>;
    create: RequestState<User | undefined>;
    update: RequestState<User | undefined>;
    remove: RequestState<User | undefined>;
    resetPassword: RequestState<User | undefined>;
}

const initialState: UsersState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    roles: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
        success: false,
    },
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    create: {
        data: undefined,
        loading: false,
        success: false,
    },
    update: {
        data: undefined,
        loading: false,
        success: false,
    },
    remove: {
        data: undefined,
        loading: false,
        success: false,
    },
    resetPassword: {
        data: undefined,
        loading: false,
        success: undefined,
        error: undefined,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    UsersState['list'],
    UserListPayload,
    ListResponse<User>
>('users/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const roles = new EzeeAsyncAction<
    UsersState['roles'],
    UserListPayload,
    ListResponse<Role>
>('users/roles', initialState.roles, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.roles,
    }),
});

export const details = new EzeeAsyncAction<
    UsersState['details'],
    UserDetailsPayload,
    User
>('users/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const create = new EzeeAsyncAction<
    UsersState['create'],
    User,
    User
>('users/create', initialState.create, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

export const update = new EzeeAsyncAction<
    UsersState['update'],
    User,
    User
>('users/update', initialState.update, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const remove = new EzeeAsyncAction<
    UsersState['remove'],
    User,
    User
>('users/remove', initialState.remove, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

export const resetPassword = new EzeeAsyncAction<
    UsersState['resetPassword'],
    User,
    User
>('users/resetPassword', initialState.remove, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

// Reducer

export const usersReducer = combineReducers<UsersState>({
    details: details.reducer,
    list: list.reducer,
    roles: roles.reducer,
    create: create.reducer,
    update: update.reducer,
    remove: remove.reducer,
    resetPassword: resetPassword.reducer,
});

// Saga

export function* usersSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(roles.type.trigger, simpleAsyncSaga(rolesApiCall, roles));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(resetPassword.type.trigger, simpleAsyncSaga(resetPasswordApiCall, resetPassword));
}

// Store helpers

export const getUsersState = (state: MainReducerState) => state.users;
