import { defineMessages } from 'react-intl';

const messages = defineMessages({
    error_required: {
        id: 'form.error_required',
        defaultMessage: 'Champ obligatoire',
    },
    error_plate: {
        id: 'form.error_plate',
        defaultMessage: 'Numéro de plaque invalide',
    },
    error_old_password_not_match: {
        id: 'form.error_old_password_not_match',
        defaultMessage: 'Votre ancien mot de passe est invalide',
    },
    all_fields_required: {
        id: 'form.all_fields_required',
        defaultMessage: 'Tous les champs sont obligatoires',
    },
    all_fields_required_except: {
        id: 'form.all_fields_required_except',
        defaultMessage: 'Les champs ci-dessus sont obligatoires, sauf si mentionné. ',
    },
    error_default: {
        id: 'form.error_default',
        defaultMessage: 'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur',
    },
    update_success: {
        id: 'form.update_success',
        defaultMessage: 'Mise à jour effectuée avec succès',
    },
    contact_success: {
        id: 'form.contact_success',
        defaultMessage: 'Votre message a été envoyé avec succès',
    },
    user_type_label: {
        id: 'form.user_type_label',
        defaultMessage: 'Vous êtes :',
    },
    user_type_individual: {
        id: 'form.user_type_individual',
        defaultMessage: 'Particulier',
    },
    user_type_company: {
        id: 'form.user_type_company',
        defaultMessage: 'Société',
    },
    civility_label: {
        id: 'form.civility_label',
        defaultMessage: 'Civilité :',
    },
    civility_mme: {
        id: 'form.civility_mme',
        defaultMessage: 'Mme',
    },
    civility_mr: {
        id: 'form.civility_mr',
        defaultMessage: 'Mr',
    },
    username_label: {
        id: 'form.username_label',
        defaultMessage: 'Nom d’utilisateur',
    },
    email_label: {
        id: 'form.email_label',
        defaultMessage: 'Adresse e-mail',
    },
    email_error: {
        id: 'form.email_error',
        defaultMessage: 'Veuillez renseigner votre e-mail',
    },
    password_label: {
        id: 'form.password_label',
        defaultMessage: 'Mot de passe',
    },
    current_password_label: {
        id: 'form.current_password_label',
        defaultMessage: 'Mot de passe actuel',
    },
    new_password_label: {
        id: 'form.new_password_label',
        defaultMessage: 'Nouveau mot de passe',
    },
    confirm_password_label: {
        id: 'form.confirm_password_label',
        defaultMessage: 'Confirmer le mot de passe',
    },
    password_error: {
        id: 'form.password_error',
        defaultMessage: 'Veuillez renseigner votre mot de passe',
    },
    password_invalid: {
        id: 'form.password_invalid',
        defaultMessage: 'Mot de passe non valide',
    },
    password_instructions_title: {
        id: 'form.password_instructions_title',
        defaultMessage: 'Votre mot de passe doit contenir :',
    },
    password_instructions_1: {
        id: 'form.password_instructions_1',
        defaultMessage: '10 caractères minimum',
    },
    password_instructions_2: {
        id: 'form.password_instructions_2',
        defaultMessage: 'Au moins un chiffre',
    },
    password_instructions_3: {
        id: 'form.password_instructions_3',
        defaultMessage: 'Au moins une lettre en majuscule',
    },
    password_instructions_4: {
        id: 'form.password_instructions_4',
        defaultMessage: 'Au moins un caractère spécial',
    },
    globalPayment_secret: {
        id: 'form.secret',
        defaultMessage: 'Secret',
    },
    globalPayment_merchant_id: {
        id: 'form.globalPayment_merchant_id',
        defaultMessage: 'Merchant id',
    },
});

export default messages;
